.layout{
    height: 100px;
    padding: 20px;
    margin-bottom: 30px;
}
.header{
    display: flex;
    width: 100%;
}
.header-title{
    font-size: 1.1rem;
    font-weight: 700;
    letter-spacing: .4px;
    width: 90%;
}
.header-status{
    font-size: 0.9rem;
    font-weight: 600;
    width: 200px;
    text-align: right;
}
.up{
    color: #2FCC66;
}
.down{
    color: #e70f0f;
}
.partialdown{
    color: #FF8C00;
}
.day-app-status-wrapper{
    display: flex;
    margin-top: 20px;
}
.day-app-status-bar{
    border: none;
    height: 40px;
    width: 5px;
    margin-left: 0.55%;
    position: relative;
}
.day-stat-content-data{
    font-size: 0.8rem;
    font-weight: 300;
    text-align: left;
}
.day-app-status-up{
    background-color: #2FCC66;
}
.day-app-status-partial-outage-low{
    background-color: #d9f506;
}
.day-app-status-partial-outage-medium{
    background-color: #f5d106;
}
.day-app-status-partial-outage-high{
    background-color: #FF8C00;
}
.day-app-status-down{
    background-color: #f70202;
}
.day-app-status-up{
    background-color: #2FCC66;
}
.day-app-status-bar:hover{
    background-color:#871FFF
}
.day-app-status-bar:hover .day-app-status-tooltip{
    display:block;
}
.footer{
    display: flex;
    margin: auto;
    margin-top: 20px;
    align-items: center;
    color: #aaaaaa;
    font-size: .9rem;
}
.footer-line{
    width: 35%;
    border-top: solid 2px;
    border-color: #E0E0E0;
    margin-left: 10px;
    margin-right: 10px;
}
.day-app-status-tooltip{
    position: absolute;
    width: 325px;
    padding:10px;
    background:#fff;
    text-align:center;
    display:none;
    border: 1px solid rgba(0,0,0,0.2);
    z-index: 2;
    box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
    top: 100%;
    left: -166px;
    margin-top: 15px;
}
.day-app-status-tooltip::after{
    content: "";
    position: absolute;
    bottom:  calc(100% - 10px);;
    left: 50%;
    width: 20px;
    height: 20px;
    background-color: #FFFF;
    margin-left: -16px;
    border: 1px solid rgba(0,0,0,0.2);
    border-color:  rgba(0,0,0,0.2) transparent  transparent  rgba(0,0,0,0.2);
    transform: rotate(45deg);
}
.day-stat{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
}
.day-stat-header{
    font-size: .8rem;
    font-weight: 700;
    margin-bottom: 10px;
}
.day-stat-content-alert{
    width: 100%;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f4f5f7;
    border: 1px solid rgba(255,255,255,0);

}
.alert-icon{
    color: #FF8C00;
    height: 20px;
    margin-right: 5px;
    margin-left: 5px;
}