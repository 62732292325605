.layout{
    margin: auto;
    width:65%;
    margin-top: 40px;
}
.overall-status{
    width: 98%;
    margin: auto;
    height: 50px;
    border-radius: 5px;
}
.overall-status-all-app-up{
    background-color: #2ecc71;
}
.overall-status-all-app-down{
    background-color: #dc7c0f;
}
.overall-status .text{
    color: #FFFF;
    font-size: 1.2rem;
    padding-top: 1.3%;
    padding-left: 10px;
    font-weight: 700;
}
.layout-header{
    margin-bottom: 30px;
    display: flex;
    width: 98%;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0);
    padding: 5px;
    box-shadow: 8px 8px 12px -2px rgba(172, 173, 175, 0.4), -6px -6px 12px -1px white;
    border-radius: 20px
}
.layout-header div:last-child{
    margin-left: 350px;
}
.layout-header img{
    width: 350px;
}
.layout-header-child{
    font-family: Spezia,Spezia_SemiMono,Arial,Helvetica,sans-serif;
    padding: 10px;
    font-weight: 700;
    font-size: 1.8rem;
    border-radius: 10px;
    color: #6D7587;
    border: 1px solid rgba(255, 255, 255, 0);
    
    
}
.layout-header button{
    background-color:#871FFF;
    border:none;
    color: #FFFF;
    font-size: .8rem;
    height: 40px;
    border-radius: 5px;
    font-weight: 900;
    padding-left: 20px;
    padding-right: 20px;
    width: max-content;
}

.content{
    border: 1px solid;
    border-color: #E0E0E0;
    margin-top: 10px;
    border-radius: 5px;
}
.content-divider{
    border-top: 1px solid;
    border-color: #E0E0E0;
    height: 0px;
}
.time-zone{
    margin-top: 30px;
    margin-left: 10px;
    color:blue;
    font-weight: 700;
}